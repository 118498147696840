import React from 'react';
import './App.css';
import gif from "./assets/computer-unvision-gif.gif"
import appstore from "./assets/appstorelogo.png"

import {
  BrowserView,
  MobileView,
} from "react-device-detect";


function App() {
  return (
    <div className="App">
     
    <BrowserView>
          <div className="block">
            <div className="left">
            Capture the world<br />
            Like a machine sees it<br />
            <a href="https://apps.apple.com/us/app/id1522388649" target="_BLANK" rel="noopener noreferrer">
            <img alt="appstore" className="logoappstore" src={appstore}  />
            </a>
            
            </div>
            <div className="right">
            <img alt="gif" className="gif" src={gif} width="270" />
            </div>
          </div>
       </BrowserView>
      <MobileView>
          <div className="blockMobile">
            <div className="wordsMobile">
              Capture the world<br />
              Like a machine sees it<br />
            </div>
            <div className="appstoreMobile">
            <a href="https://apps.apple.com/us/app/id1522388649" target="_BLANK" rel="noopener noreferrer">
             <img alt="appstore" className="logoappstore" src={appstore}  width="150" />
             </a>
            </div>
            <div className="gifMobile">
              <img alt="gif" className="gif" src={gif} width="200" />
            </div>
          </div>
     </MobileView>
       </div>
     
  );
}

export default App;
